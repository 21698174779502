// Gallery.jsx
import React from 'react';
import Layout from "../components/layout";
import SimpleReactLightbox, {SRLWrapper} from "simple-react-lightbox";
import {StaticImage} from "gatsby-plugin-image";

const GalleryUK1970 = () => {
    return (
        <Layout>
            <a href="/Gallery">Return to Gallery</a>
            <h1>UK 1970 - 1990</h1>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/UK1970/1794053_orig.jpg" alt="Sheffield poly 1988"/><StaticImage
                src="../images/gallery/UK1970/2301900_orig.jpg"
                alt="Demo with Sensei Ravey in the Aquarius nightclub, Chesterfield"/><StaticImage
                src="../images/gallery/UK1970/3135043_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/UK1970/3238028_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/UK1970/3402529_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/UK1970/3697889_orig.jpg"
                alt="Sensei Ravey in the Chesterfield Dojo"/><StaticImage
                src="../images/gallery/UK1970/3963969_orig.jpg"
                alt="Graham Ravey in the backyard with his Mum Iris, 8th kyu"/><StaticImage
                src="../images/gallery/UK1970/4055565_orig.jpg"
                alt="1979 IOGKF UK first Gasshuku in Poole"/><StaticImage
                src="../images/gallery/UK1970/4188834_orig.jpg" alt="Shisochin Kata, taken at Greystones Dojo in 1984"/><StaticImage
                src="../images/gallery/UK1970/5038505_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/UK1970/5586543_orig.jpg"
                alt="Chersterfield and Sheffield dojo students circa 1986"/><StaticImage
                src="../images/gallery/UK1970/5641536_orig.jpg"
                alt="Sensei Ravey left in the All Japan Goju Ryu championships, held at Kokugikan Sumo Hall in Tokyo May 1986, taken by Andy Barker."/><StaticImage
                src="../images/gallery/UK1970/5780240_orig.jpg"
                alt="Sensei Gavin Hall and Sensei Higoanna, London."/><StaticImage
                src="../images/gallery/UK1970/6932588_orig.jpg"
                alt="Sensei Steven Bellamy in his Sheffield Dojo 1971."/><StaticImage
                src="../images/gallery/UK1970/7253246_orig.jpg"
                alt="Sensei Richard Wood and Sensei Steve Shaw training on Kinder in the winter. Sensei Steve Shaw still does this now with some of his students at Christmas"/><StaticImage
                src="../images/gallery/UK1970/7309563_orig.jpg" alt="Chesterfield - 1980"/><StaticImage
                src="../images/gallery/UK1970/7377246_orig.jpg"
                alt="Demo with Sensei Ravey in the Aquarius nightclub, Chesterfield"/><StaticImage
                src="../images/gallery/UK1970/768400_orig.jpg"
                alt="Demo with Sensei Ravey in the Aquarius nightclub, Chesterfield"/><StaticImage
                src="../images/gallery/UK1970/7893635_orig.jpg"
                alt="Sensei Steve Legdon presenting Sensei Ravey with a sword following one of Sensei Ravey's visits to the UK. Sword made by Harry Booth."/><StaticImage
                src="../images/gallery/UK1970/8123313_orig.jpg" alt="Chesterfield dojo 1980s"/><StaticImage
                src="../images/gallery/UK1970/8925553_orig.jpg" alt="Sensei Ravey with student Peter Geldard"/>
            </SRLWrapper></SimpleReactLightbox>
        </Layout>
    );
}

export default GalleryUK1970;
